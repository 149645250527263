
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="collection" class="col-12">
      <h5 class="mb-3">Show collection</h5>
      <div class="card mb-4 overflow-hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-10 col-sm-8">
              <div class="d-flex align-items-center justify-content-start mb-3">
                <div class="">
                  <div class="friendly-id mb-1">
                    {{collection.friendly_id}}
                  </div>
                  <div class="">
                    <strong class="me-1">Status:</strong>
                    <span v-html="statusBadge(collection.status)"> </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-end">
              <div class="dropdown dropstart no-arrow">
                <button class="dropdown-toggle btn btn-sm btn-light" type="button" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </button>
                <ul class="dropdown-menu">
                </ul>
              </div>
            </div>
            <div class="col-12">
              <h4 class="mb-0">{{collection.title}}</h4>
              <div class="mt-2 pt-1 text-muted">Collection scheduled between: <strong>{{this.$filters.date(collection.started_at,1)}}</strong> and <strong>{{this.$filters.date(collection.ended_at,1)}}</strong></div>
              <p v-if="collection.description" class="mt-2 mb-0 pt-1 text-muted">{{collection.description}}</p>
            </div>
          </div>          
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <statistic-apex-count
            title="Attendees"
            :count-end="collection.attendees_count"
            :total="collection.invitations_count"
            :figure="collection.attendees_count"
          />
        </div>
        <div class="col-md-6">
          <statistic-apex-count
            title="Absentees"
            :count-end="collection.absentees_count"
            :total="collection.invitations_count"
            :figure="collection.absentees_count"
            />
        </div>
      </div>
      <div class="row mb-2 gy-2">
        <div class="col-12">
          <invitations />
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import Invitations from './Invitations.vue';
import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';

export default {
  name: "collection-show",
  components:{
    IsLoading,
    Invitations,
    StatisticApexCount,
  },
  data() {
    return {
      isLoading: true,
      collection: null,
    }
  },
  watch: {
    '$route.params.collectionId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.collection, item)
    },
    fetchItem(){
      this.isLoading = true
       this.$http.get(`/collections/${this.$route.params.collectionId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.collection = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

